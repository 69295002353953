<template>
  <div class="container-fluid text-left">
    <div class="card nw-card mt-3">
      <div class="card-header nw-card-header">
        <h4>Add Patient(s)</h4>
      </div>
      <div class="card-body text-left">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="control-label">Patient ID</label>
              <input class="form-control" v-model="model.participantID"  type="text" placeholder="######"/>
            </div>
          </div>
          <div class="col-6" v-show="model.multipleFlag==false">
            <div class="form-group">
              <label class="control-label">Access Code</label>
              <input class="form-control" v-model="model.accessCode"  type="text" placeholder="AAAAAA"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <label class="control-label">Add Multiple</label><br/>
            <toggle v-model="model.multipleFlag" color="green" on-text="On" off-text="Off"></toggle>
          </div>
          <div class="col-4" >
            <div class="form-group" v-show="model.multipleFlag==true">
              <label class="control-label">Quantity</label><br/>
              <input class="form-control" v-model="model.addCount"  type="text"/>
            </div>
          </div>
          <div class="col-2">
            <label class="control-label">Study Participant</label><br/>
            <toggle v-model="model.studyFlag" color="green" on-text="On" off-text="Off"></toggle>
          </div>
          <div class="col-4">
            <div class="form-group" v-show="model.studyFlag==true">
              <label class="control-label">Study Code</label>
              <input class="form-control" v-model="model.studyCode"  type="text"/>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-danger mt-2 ml-2 mr-2" role="alert" v-show="resp.length > 0">
        <ul>
          <li v-for="item in resp">{{ item.msg}}</li>
        </ul>
      </div>
      </div>
      <div class="text-center mb-5 mt-2">
        <button class="btn nw-btn-success" :disabled="isDirty==false" @click="add()">Add</button>
      </div>

    </div>

</template>

<script>
import Toggle from '@/components/Toggle'
import NetClient from '@/util/netclient.js'

export default {
  components: { Toggle },

  data () {
    return {
      nc: NetClient,
      activePage: 'step1',
      model: {
        action: 'add',
        corrID: 1,
        participantID: '',
        accessCode: '',
        multipleFlag: false,
        studyFlag: false,
        studyCode: '',
        addCount: 1
      },
      resp: []
    }
  },
  methods: {
    add () {
      this.resp = []
      this.model.corrID = this.addMultiple ? 2 : 1
      NetClient.doRequest('rest/patient',  this.model).then(msg => {
        if(typeof msg.resp != 'undefined') this.resp = msg.resp
        if('OK' == msg.retval)
        {
          if(typeof msg.data.ctx != 'undefined') this.$router.push({ name: 'Patient'})
          if('OK' == msg.retval && this.model.multipleFlag == true ) this.$router.push({ name: 'ParticipantBatch'})
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
